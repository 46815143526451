import React, { forwardRef, ForwardRefExoticComponent } from 'react'
import {
  Box,
  BoxProps,
  Divider as ChakraDivider,
  DividerProps,
  ComponentWithAs,
  As,
  useToken,
} from '@chakra-ui/react'

export interface BubbleDividerProps extends DividerProps {}

export const BubbleDivider: ComponentWithAs<
  As,
  BubbleDividerProps
> = forwardRef(({ border, bgColor, ...props }, ref) => {
  const bg = useToken('colors', bgColor)

  return (
    <ChakraDivider
      position={'relative'}
      border={border}
      borderColor={'currentColor'}
      _before={{
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 8,
        height: 8,
        top: 0,
        left: 0,
        right: 0,
        mx: 'auto',
        bg: `linear-gradient(-45deg, transparent 0 50%, ${bg} 50% 100%)`,
        borderColor: 'currentColor',
        borderLeft: border,
        transform: 'rotate(-45deg)',
        transformOrigin: 'top left',
      }}
      ref={ref}
      {...props}
    />
  )
}) as ComponentWithAs<As, DividerProps>

export interface BubbleProps extends BoxProps {}

export const Bubble: ComponentWithAs<As, BubbleProps> = forwardRef(
  (
    {
      borderBottom = 2,
      borderColor = 'currentColor',
      bgColor = 'white',
      ...props
    },
    ref
  ) => {
    const bg = useToken('colors', bgColor)

    return (
      <Box
        position={'relative'}
        borderBottom={borderBottom}
        borderColor={borderColor}
        _before={{
          content: '""',
          position: 'absolute',
          display: 'block',
          width: 8,
          height: 8,
          top: '102%',
          left: 0,
          right: 0,
          mx: 'auto',
          bg: `linear-gradient(-40deg, transparent 0 50%, ${bg} 50% 100%)`,
          borderLeft: borderBottom,
          borderColor: borderColor,
          transform: 'rotate(-45deg)',
          transformOrigin: 'top left',
        }}
        ref={ref}
        {...props}
      />
    )
  }
) as ComponentWithAs<As, BubbleProps>
