import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import {
  Box,
  VStack,
  Heading,
  Text,
  Divider,
  useToken,
  Container,
} from '@chakra-ui/react'
import { Section, SectionHeading } from '../components/section'
import {
  HeadingGroup,
  HeadingSubtitle,
  HeadingTitle,
} from '../components/heading-group'
import { Image } from '../components/site-only/image'
import { useEffect, useState } from 'react'
import { Carousel, CarouselArrow } from '../components/carousel'
import { Hero } from '../components/site-only/hero'
import { Bubble } from '../components/divider'

// @ts-ignore
import HeroImage from '../images/hero_01.jpg'
import { SlideFadeInView } from '../components/slide-fade-in-view'
import { splitArray } from '../utils/split-array'
import { Wrapper, WrapperFluid } from '../components/site-only/wrapper'

export default function Performance({
  data: { performance },
  location,
}: PageProps<
  GatsbyTypes.PerformancePageQuery,
  object,
  { id?: string | number }
>) {
  const primaryColor = useToken('colors', 'primary.500')
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {}, [activeIndex])

  const section = performance?.section

  const [first, second] = splitArray(
    section?.sections ?? [],
    section => {
      return section.key === location.state?.id
    },
    true
  )
  const sortedSubsections = Array.isArray(second)
    ? [...second, ...first]
    : first

  const transition = 'all 0.8s ease-in'

  return (
    <>
      {section && (
        <>
          <Hero
            bgImage={{
              base: `url(${HeroImage})`,
              md: `url(${HeroImage})`,
            }}
            bgPos={{
              base: 'right -26vw bottom 2vw',
              md: 'right bottom -17vw',
            }}
            bgSize={{
              base: '110% auto',
              md: '70% auto',
            }}
          >
            <Container>
              <SlideFadeInView>
                <Section>
                  <SectionHeading
                    as={HeadingGroup}
                    variant={'white'}
                    textAlign={{
                      base: 'center',
                      md: 'unset',
                    }}
                  >
                    <HeadingTitle>{section.heading?.value}</HeadingTitle>
                    <HeadingSubtitle>
                      {section.subtitle?.element?.value}
                    </HeadingSubtitle>
                  </SectionHeading>
                </Section>
              </SlideFadeInView>
            </Container>
          </Hero>

          <Wrapper id={'main'} invert layerStyle={'light'} overflowX={'hidden'}>
            <SlideFadeInView>
              <Box textAlign={'center'}>
                {section.contents.map(content => (
                  <Bubble
                    key={content.key}
                    display={'inline-block'}
                    fontWeight={'bold'}
                    bgColor={'light'}
                    borderColor={'secondary.500'}
                    pb={8}
                    w={{
                      base: 100,
                      md: 'unset',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: content.value,
                    }}
                  />
                ))}
              </Box>
            </SlideFadeInView>

            <WrapperFluid>
              <SlideFadeInView>
                <Box h={'40vw'} w={'100vw'} my={20}>
                  <Carousel
                    variableWidth={true}
                    centerMode={true}
                    speed={1000}
                    slidesToShow={1}
                    prevArrow={<CarouselArrow left={'10vw'} />}
                    nextArrow={<CarouselArrow right={'10vw'} />}
                    beforeChange={(_, next) => {
                      setActiveIndex(next)
                    }}
                  >
                    {sortedSubsections.map((section, index) => {
                      const image = section.contents[0]?.image?.properties

                      return (
                        <Box
                          key={section.key}
                          _focus={{
                            outline: 'none',
                          }}
                        >
                          <Box
                            transition={transition}
                            w={{
                              base: '50vw',
                            }}
                            h={{
                              base: '40vw',
                            }}
                            px={{
                              base: '2vw',
                            }}
                            py={{
                              base: index === activeIndex ? 'unset' : '2vw',
                            }}
                          >
                            <Image
                              {...image!.src}
                              alt={image!.alt}
                              transition={transition}
                              filter={{
                                base:
                                  index === activeIndex
                                    ? 'unset'
                                    : 'brightness(40%) blur(4px)',
                              }}
                              objectFit={'cover'}
                              w={{
                                base: 'full',
                              }}
                              h={{
                                base: 'full',
                              }}
                            />
                          </Box>
                        </Box>
                      )
                    })}
                  </Carousel>
                </Box>
              </SlideFadeInView>
            </WrapperFluid>

            <SlideFadeInView>
              {sortedSubsections
                .filter((_, index) => index === activeIndex)
                .map(section => (
                  <VStack
                    key={section.key}
                    as={Container}
                    size={'md'}
                    spacing={15}
                    h={150}
                  >
                    <Heading size={'xs'} textAlign={'center'}>
                      {section.heading?.value}
                    </Heading>

                    <Divider
                      as={'div'}
                      w={30}
                      border={4}
                      color={'secondary.500'}
                    />

                    {section.contents
                      .filter((_, index) => index > 0)
                      .map(content => {
                        return (
                          <Text
                            key={content.key}
                            alignSelf={'flex-start'}
                            dangerouslySetInnerHTML={{
                              __html: content.value,
                            }}
                          />
                        )
                      })}
                  </VStack>
                ))}
            </SlideFadeInView>
          </Wrapper>
        </>
      )}
    </>
  )
}

export const query = graphql`
  query PerformancePage {
    performance: markdownRemark(frontmatter: { title: { eq: "Performance" } }) {
      childrenMarkdownElement {
        childrenMarkdownElement {
          childrenMarkdownElement {
            childrenMarkdownElement {
              __typename
            }
          }
        }
      }
      section: childElement(filter: { type: { eq: "section" } }) {
        tagName
        ...MarkdownHeading
        ...MarkdownSubtitle
        contents: childrenElement(
          filter: {
            type: { eq: "paragraph" }
            children: { type: { nin: ["strong"] } }
          }
        ) {
          key: id
          tagName
          value
        }
        sections: childrenElement(filter: { type: { eq: "section" } }) {
          key: id
          ...MarkdownHeading
          ...MarkdownSubtitle
          contents: childrenElement(
            filter: {
              type: { eq: "paragraph" }
              children: { type: { nin: ["strong"] } }
            }
          ) {
            key: id
            tagName
            value
            ...MarkdownLink
            ...MarkdownImage
          }
        }
      }
    }
  }
`
