export const defaultSplitCond = (item: any) => item.type === 'thematicBreak'

export function splitArray<T>(
  array: readonly T[],
  cond: (item: T) => boolean = defaultSplitCond,
  includeDelimiter = false
): T[][] {
  let index = 0
  return array.reduce<T[][]>(
    (prev, current) => {
      if (cond(current)) {
        index++

        if (!includeDelimiter) {
          return prev
        }
      }

      if (Array.isArray(prev[index])) {
        prev[index].push(current)
      } else {
        prev.push([current])
      }
      return prev
    },
    [[]]
  )
}
